// flex
.frce {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.frec {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.frcc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.frcb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.frec {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.frca {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.frcev {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.frcs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.frss {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.fccc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fccs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.fcsc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.fcsb {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.fc {
  display: flex;
  flex-direction: column;
}

.fr {
  display: flex;
  flex-direction: row;
}

.frsb {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.frb {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: space-between;
}

.fw {
  flex-wrap: wrap;
}

.jss {
  justify-self: flex-start;
}

.ass {
  align-self: flex-start;
}

// margins
.no-m {
  margin: 0 !important;
}
.m-0-5 {
  margin: 0.5rem !important;
}
.m-1 {
  margin: 1rem !important;
}
.m-1-5 {
  margin: 1.5rem !important;
}
.m-2 {
  margin: 2rem !important;
}
.mb-0-5 {
  margin-bottom: 0.5em !important;
}
.mb-1 {
  margin-bottom: 1em !important;
}
.mb-1-5 {
  margin-bottom: 1.5em !important;
}
.mb-2 {
  margin-bottom: 2em !important;
}
.mb-3 {
  margin-bottom: 3em !important;
}
.mt-0-5 {
  margin-top: 0.5em !important;
}
.mt-1 {
  margin-top: 1em !important;
}
.mt-1-5 {
  margin-top: 1.5em !important;
}
.mt-2 {
  margin-top: 2em !important;
}
.mt-3 {
  margin-top: 3em !important;
}
.mt-3 {
  margin-top: 3em !important;
}
.mr-0-5 {
  margin-right: 0.5rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-1-5 {
  margin-right: 1.5rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.ml-0-5 {
  margin-left: 0.5rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-1-5 {
  margin-left: 1.5rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}

.my-0-5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-1-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.mx-0-5 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-1-5 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.p-0-5 {
  padding: 0.5rem !important;
}
.p-1 {
  padding: 1rem !important;
}
.p-1-2 {
  padding: 1.5rem !important;
}
.p-2 {
  padding: 2rem !important;
}
.pt-1 {
  padding-top: 1rem !important;
}

.px-0-5 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.px-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

// colors
.c-primary {
  color: var(--labgo-primary);
}
.c-accent {
  color: var(--labgo-accent);
}
.c-warn {
  color: var(--labgo-warn);
}

.m-0-auto {
  margin: 0 auto;
}

// width
.mw-700 {
  max-width: 700px;
}
.mw-360 {
  max-width: 360px;
}
.mw-320 {
  max-width: 320px;
}
.w-100 {
  width: 100%;
}
.w-limited {
  max-width: 95%;
  @media screen and (min-width: 320px) {
    max-width: 90%;
  }
  @media screen and (min-width: 600px) {
    max-width: 1280px;
  }
  margin-left: auto;
  margin-right: auto;
}

// p
.p-center {
  text-align: center;
}
.p-small {
  font-size: 12px;
  line-height: 16px;
}

.bold {
  font-weight: bold;
}

.shadow {
  box-shadow: 0px 4px 15px rgb(102 102 102 / 20%);
}

.shadow-inv {
  box-shadow: 0px -4px 15px rgb(102 102 102 / 20%);
}

.abs-centered {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap-0-5 {
  gap: 0.5rem;
}
.gap-1 {
  gap: 1rem;
}
.gap-1-5 {
  gap: 1.5rem;
}
.gap-2 {
  gap: 2rem;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.pre-wrap {
  white-space: pre-wrap;
}

.f-wrap {
  flex-wrap: wrap;
}

.c-text {
  color: var(--oxy-text);
}

.c-text-light {
  color: var(--oxy-text-light);
}

.c-white {
  color: #fff;
}

.b-white {
  background-color: #fff;
}

.underline {
  text-decoration: underline;
}

.spinner {
  position: relative;
}
.spinner::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ffffff33;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  border-radius: 50%;
  border: 1.5px solid #ffffff33;
  border-top-color: #000000cc;
  animation: spinner 1s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
  z-index: 9999;
}
button.spinner::before {
  margin-top: -6.5px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.hide-m {
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}
.show-m {
  @media screen and (min-width: 1024px) {
    display: none !important;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.7;
  }
}
