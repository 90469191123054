table.ermit-table {
  --ermit-table-row-height: 56px;
  --ermit-table-header-row-height: 36px;
  .mat-mdc-cell {
    font-weight: 500;
  }
  &.mat-mdc-table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  .mat-mdc-header-row {
    height: var(--ermit-table-header-row-height);
  }
  .mat-mdc-header-cell {
    border: none;
  }
  .mat-mdc-cell {
    border: none;
  }

  .mat-mdc-header-row,
  .mat-mdc-header-cell {
    color: var(--oxy-text-light);
    text-transform: uppercase;
  }
  tr.mat-mdc-row {
    --mat-table-row-item-container-height: var(--ermit-table-row-height);
    background: var(--oxy-background-light);
    &:hover {
      opacity: 1;
    }
  }
}

table.mini-table {
  .mat-mdc-cell {
    font-weight: 500;
  }
  .mat-mdc-header-row {
    height: 52px;
  }
  .mat-mdc-header-cell {
    border: none;
  }
  .mat-mdc-cell {
    border: none;
    &:first-child {
      padding-left: 0;
    }
  }

  .mat-mdc-header-row,
  .mat-mdc-header-cell {
    color: var(--oxy-text-light);
    // background: var(--oxy-background-light);
    background: #fff;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
  }
  tr.mat-mdc-row {
    --mat-table-row-item-container-height: 32px;
    // background: var(--oxy-background-light);
    background: #fff;
    &:hover {
      background: #fcfcfc;
    }
  }
}

.td-cursor{
  cursor: pointer;
}

.td-player-name {
  font-weight: bold;
}

.table-empty {
  background: var(--oxy-background-light);
  color: var(--oxy-text-light);
  border-radius: 8px;
}

.table-col-value {
  font-weight: bold;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 10px 0 10px;
}
