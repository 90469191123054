.boxChart__container__title {
  .b {
    mat-icon {
      --mdc-icon-button-state-layer-size: 24px;
    }

    &.b-br {
      color: var(--oxy-br);
      mat-icon {
        --mdc-icon-button-state-layer-size: 24px;
      }
    }
    &.b-ve {
      color: var(--oxy-ve);
    }
  }
  h5.l {
    font-size: 16px;
    border-bottom: 3px solid;
    &.l-vo2 {
      color: var(--oxy-vo2);
      border-color: var(--oxy-vo2);
      margin-left: 0.5rem;
    }
    &.l-br {
      color: var(--oxy-br);
      border-color: var(--oxy-br);
      margin-right: 1rem;
    }
    &.l-bpm {
      color: var(--oxy-bpm);
      border-color: var(--oxy-bpm);
      margin-left: 0.5rem;
    }
    &.l-ve {
      color: var(--oxy-ve);
      border-color: var(--oxy-ve);
      margin-right: 1rem;
    }
  }
}
