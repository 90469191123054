:root {
  --oxy-primary: #0a1118;
  // --oxy-accent: #023fdb;
  --oxy-accent: #023fdb;

  --oxy-info: #2f80ed;
  --oxy-success: #27ae60;
  --oxy-warning: #f2994a;
  --oxy-error: #eb5757;

  --oxy-gray: #98a4b8;
  --oxy-gray-light: #e6ebf3;
  --oxy-gray-dark: #2e3b47;
  --oxy-text: #0a1118;
  --oxy-text-light: #77899a;

  --oxy-background-dark: #0a1118;
  --oxy-background: #fff;
  // --oxy-background-light: #f4f6f8;
  --oxy-background-light: #f0f1f2;

  --oxy-bpm: #981a32;
  --oxy-vo2: #2851dd;
  --oxy-ve: #549f97;
  --oxy-br: #f79d0c;

  --oxy-z0: #9cd9ff;
  --oxy-z1: #78c7f7;
  --oxy-z2: #39a9ec;
  --oxy-z3: #50af12;
  --oxy-z4: #f79d0c;
  --oxy-z5: #f4253c;

  --oxy-session: #ffebd2;
}
