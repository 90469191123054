* {
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

h1 {
  font-size: 34px;
  line-height: 48px;
  font-weight: 700;
}
h2 {
  font-size: 29px;
  line-height: 40px;
  font-weight: 700;
}
h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
h4 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
}
h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: var(--oxy-text-light);
}

p,
span,
strong {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  &.small {
    font-size: 12px;
    line-height: 16px;
  }
  &.large {
    font-size: 16px;
    line-height: 24px;
  }
}
strong {
  font-weight: 700;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.fw-thin {
  font-weight: 300;
}

.fw-normal {
  font-weight: 500;
}

.fw-bold {
  font-weight: 700;
}
