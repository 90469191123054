@use "@angular/material" as mat;
@include mat.core();

@import "theme";

$fonts: mat.m2-define-typography-config(
  $font-family: $md-font,
);
@include mat.typography-hierarchy($fonts);
$app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette($md-oxyprimary),
      accent: mat.m2-define-palette($md-oxyaccent),
      warn: mat.m2-define-palette($md-oxywarning),
    ),
    typography: $fonts,
  )
);
@include mat.all-component-themes($app-theme);

@import "typography";
@import "p2a";
@import "core";
@import "tables";
@import "charts";

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

mat-select-trigger {
  display: flex;
}

mat-icon.title-icon {
  background: var(--oxy-text);
  color: #fff;
  &.dark {
    background: #fff;
    color: var(--oxy-text);
  }
  width: 40px;
  height: 40px;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-content {
  flex: 1;
  .side {
    width: 25%;
    min-width: 350px;
    max-width: 600px;
  }
  .content {
    flex: 1;
  }
}

// -----
mat-form-field.mat-mdc-form-field {
  flex: 1;
  min-width: 250px;
}

button.title-button,
button.table-button {
  --icon-size: 20px;
  min-width: auto;
  padding: 0 1rem;
  overflow: hidden;
  white-space: nowrap;
  &.mat-mdc-button-base {
    height: 40px;
  }
  .mat-icon {
    margin: 0 0.5rem 0;
    color: #fff;
    --icon-size: 20px;
    font-size: var(--icon-size);
    width: var(--icon-size);
    height: var(--icon-size);
  }
  &[disabled] {
    mat-icon {
      color: var(--oxy-gray);
    }
  }
}

button.square-table-button {
  --icon-size: 20px;
  min-width: auto;
  padding: 0 1rem;
  overflow: hidden;
  white-space: nowrap;
  background: transparent;
  &.mat-mdc-button-base {
    height: 40px;
  }
  .mat-icon {
    margin: 0;
    --icon-size: 20px;
    font-size: var(--icon-size);
    width: var(--icon-size);
    height: var(--icon-size);
  }
  &[disabled] {
    mat-icon {
      color: var(--oxy-gray);
    }
  }
}

.mat-primary.mat-mdc-raised-button:disabled {
  --mdc-protected-button-disabled-container-color: #e0e0e0;
  // --mdc-protected-button-disabled-label-text-color: #fff;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 40px;
}

.mdc-button {
  --mdc-outlined-button-outline-color: var(--oxy-text);
  --mdc-outlined-button-label-text-color: var(--oxy-text);
  --mdc-outlined-button-outline-width: 2px;
  --mdc-typography-button-font-weight: bold;
  &.reverse {
    flex-direction: row-reverse;
    mat-icon {
      margin-left: 8px;
      margin-right: -4px;
    }
  }
}

.mat-mdc-outlined-button .mdc-button__label {
  font-weight: bold;
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: #fff;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  & > div {
    display: flex;
    gap: 1rem;
  }
}

// mat-progress-bar.mat-mdc-progress-bar {
//   position: fixed;
// }

.labgo-table-empty-with-icon {
  color: var(--oxy-gray);
}

.cdk-column-surname,
.cdk-column-name {
  width: 150px;
}

.cdk-column-ops {
  width: 60px;
}

page-content {
  // max-width: 740px;
}

.mw-1280 {
  max-width: 1280px;
}

.w-100 {
  width: 100%;
}

.green-snackbar {
  --mat-snack-bar-button-color: var(--oxy-text);
  --mdc-snackbar-supporting-text-weight: bold;
  --mdc-snackbar-supporting-text-color: var(--oxy-text);
  --mdc-snackbar-container-color: var(--oxy-success);
}

.red-snackbar {
  --mat-snack-bar-button-color: var(--oxy-text);
  --mdc-snackbar-supporting-text-weight: bold;
  --mdc-snackbar-supporting-text-color: var(--oxy-text);
  --mdc-snackbar-container-color: var(--oxy-error);
}

.blue-snackbar {
  --mat-snack-bar-button-color: var(--oxy-text);
  --mdc-snackbar-supporting-text-weight: bold;
  --mdc-snackbar-supporting-text-color: var(--oxy-text);
  --mdc-snackbar-container-color: var(--oxy-info);
}

.mdc-text-field--disabled .mdc-text-field__input,
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.5);
}

.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.25);
}

fieldset {
  border: 1px solid #9e9e9e;
  border-radius: 2px;
  position: relative;
  legend {
    padding: 0 0.5rem;
  }
}

.mat-mdc-form-field-error {
  display: block;
  overflow: auto;
  max-height: 22px;
}

.language-selector.mat-mdc-select {
  display: flex;
  align-items: center;
}
.language-selector-trigger,
.language-selector-option .mdc-list-item__primary-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.mat-mdc-autocomplete-panel {
  .mdc-list-item__primary-text {
    display: flex;
    flex: 1;
  }
}

div.mat-mdc-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

h2.mat-mdc-dialog-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.mat-mdc-dialog-actions {
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.real-time-icon {
  background: var(--oxy-gray-dark);
  color: #fff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.title-row {
  strong {
    font-size: 16px;
  }
  .chip {
    height: 36px;
    border-radius: 18px;
    background-color: var(--oxy-success);
    color: #fff;
    font-weight: bold;
    padding: 0 1rem;
    gap: 0.5rem;
    &.no-connection {
      background-color: var(--oxy-warning);
    }
  }
}

.subtitle-row {
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--oxy-gray-light);
  font-size: 16px;
  font-weight: 500;
}

:root {
  --mat-menu-container-color: var(--oxy-background-dark);
  --mat-menu-item-label-text-color: #fff;
  --mat-menu-item-icon-color: #fff;
  --mdc-outlined-text-field-disabled-outline-color: var(--oxy-text-light);
  --mdc-outlined-text-field-disabled-label-text-color: var(--oxy-text-light);
  --mat-standard-button-toggle-selected-state-text-color: #fff;
  --mat-standard-button-toggle-selected-state-background-color: var(
    --oxy-primary
  );
  --mat-standard-button-toggle-divider-color: var(--oxy-background-light);
}

.p-rel {
  position: relative;
}
.abs-spinner-container {
  position: relative;
  min-height: 150px;
  min-height: 100%;
}
.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e2e2e2;
  opacity: 0.6;
  z-index: 9;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea)
  .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  height: 20px;
}

.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: var(--oxy-text-light);
  --mdc-filled-button-disabled-label-text-color: #fff;
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.card {
  background: #fff;
  padding: 0.5rem;
  // flex: 1;
  border-radius: 8px;
  // box-shadow: 0px 4px 15px rgb(102 102 102 / 20%);
  .card-title {
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: var(--oxy-text);
    }
  }
}

.oxy-dialog {
  .mdc-dialog__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  div.mat-mdc-dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem 6rem 2rem;
  }
}

.dark {
  .mdc-text-field--outlined {
    border-color: red;
    background: #fff;
  }
}

.text-danger {
  color: var(--oxy-error);
}

.text-bold {
  font-weight: bold;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #77899a;
}

mat-icon.square-icon {
  --size: 22px;
  font-size: var(--size);
  height: var(--size);
  width: var(--size);
  color: var(--oxy-text);
  padding: 12px;
  &.vo2-opa {
    background-color: #f0ede3;
  }
  &.bpm-opa {
    background-color: #e5eeeb;
  }
}
